import AimtecEditor from './editor.js';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

import { validator, ValidatorPlugin } from './validator.js';

class Editor extends ClassicEditor { }

Editor.builtinPlugins = [...AimtecEditor.builtinPlugins, ValidatorPlugin];

const config = Editor.defaultConfig = {
    isCKeditorConfig: true,
    toolbar: {
        items: [
            'findAndReplace',
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'fontBackgroundColor',
            'fontColor',
            'removeFormat',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'imageUpload',
            'insertTable',
            '|',
            'validator'
        ]
    },
    language: 'en',
    image: {
        toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    }
};

const TGCeditor = { Editor, validator, config };

export default TGCeditor;