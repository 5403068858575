import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageCompression from 'browser-image-compression';

const version = '23.08.10.00';
const maxFile = 1048576;

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

const queries = [
    [['*:not(li) > ol', '*:not(li) > ul'], (element) => { element.innerHTML = element.innerHTML.replace(/<(\/?)[ou]l>/gi, (_, t) => '<' + t + element.tagName + '>'); }],
    [['a'], (element) => { element.innerText = element.textContent; }],
    [['ol', 'ul', 'li'], (element) => { element.removeAttribute("style"); }],
    [['li p', 'td p', 'th p'], (element) => {
        let span = document.createElement('span');
        span.innerHTML = element.innerHTML;
        element.parentElement.replaceChild(span, element);
    }],
    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6'], (element) => {
        let strong = document.createElement('p');
        strong.innerHTML = '<strong>' + element.innerHTML + '</strong>';
        element.parentElement.replaceChild(strong, element);
    }],
    [['img'], (element) => {
        const url = new URL(element.src);
        return new Promise((done) => {
            if (url.protocol != 'https:' && url.protocol != 'http:') {
                element.alt = element.alt || 'image';
                fetch(element.src)
                    .then(x => x.blob())
                    .then(x => imageCompression(x))
                    .then(x => blobToBase64(x))
                    .then(x => { console.log(`img ${Math.floor(x.length * (100 / element.src.length))}%`); element.src = x; done(element); })
                    .catch(() => { done(element); });
            } else {
                done(element);
            }
        });
    }],
    [['img', 'table'], (element) => { element.removeAttribute('align'); }]
];

export async function validator(source) {
    console.group(`validator ${version}`)
    let doc = new DOMParser().parseFromString(source, "text/html");
    for (const [query, updater] of queries) {
        for (const element of doc.querySelectorAll(query.join())) {
            await updater(element);
        }
    }
    const data = doc.body.innerHTML;
    if (data.length > maxFile) { console.error('Text is too long!'); console.groupEnd(); throw 'Text is too long!'; }
    console.groupEnd();
    return data;
}

export class ValidatorPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const button = new ButtonView();
        editor.model.document.on('change:data', () => {
            button.tooltip = false;
            button.element.style.backgroundColor = null;
            button.element.innerText = 'Validate';
        });
        button.set({ label: 'Validate', withText: true });
        button.on('execute', () => {
            button.tooltip = 'Working...';
            button.element.style.backgroundColor = null;
            button.element.disabled = true;
            button.element.innerText = 'Validate ⏳';
            validator(editor.getData())
                .then(data => {
                    editor.setData(data);
                    button.tooltip = 'Validation was successful';
                    button.element.style.backgroundColor = 'greenyellow';
                    button.element.innerText = 'Validate ✔';
                })
                .catch(er => {
                    button.tooltip = er;
                    button.element.style.backgroundColor = 'red';
                    button.element.innerText = 'Validate ❗';
                })
                .finally(() => {
                    button.element.disabled = false;
                });
        });
        editor.ui.componentFactory.add('validator', () => button);
    }
}